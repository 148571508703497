<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="分类名称"
      prop="name"
      :rules="[
        {
          validator: checkName,
          trigger: 'change'
        },
        {
          required: true,
          message: '请填写分类名称',
          trigger: 'change'
        }
      ]"
    >
      <a-input v-model.trim="form.name" placeholder="请输入" :maxLength="25">
        <a-icon slot="suffix" :type="checkingName ? 'loading' : ''" />
      </a-input>
    </a-form-model-item>

    <a-form-model-item v-if="!hasPid" label="上级分类">
      <h-select v-model="form.parentId" allowClear placeholder="请选择">
        <a-select-option v-for="fieldsInfo in fieldsOptions" :key="fieldsInfo.id" :value="fieldsInfo.id">{{
          fieldsInfo.name
        }}</a-select-option>
      </h-select>
      <div slot="extra">不选择上级分类默认为顶级分类</div>
    </a-form-model-item>
    <a-form-model-item v-else label="上级分类">
      <span>{{ (fieldsOptions.find(v => v.id === parseInt($route.query.pid)) || {}).name }}</span>
    </a-form-model-item>

    <a-form-model-item label="是否显示">
      <a-radio-group v-model="form.isShow">
        <a-radio :value="false">
          否
        </a-radio>
        <a-radio :value="true">
          是
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item
      label="图片"
      prop="url"
      :rules="{
        required: true,
        message: '请上传图片',
        trigger: 'blur'
      }"
    >
      <image-upload :url="form.url" @success="e => (form.url = e)" @remove="form.url = ''"></image-upload>
    </a-form-model-item>

    <a-form-model-item
      label="分类描述"
      :prop="!form.introduction && form.resourceList.length === 0 ? 'introduction' : ''"
      :rules="
        !form.introduction && form.resourceList.length === 0
          ? {
              required: true,
              message: '请输入描述或上传文件',
              trigger: 'blur'
            }
          : {}
      "
      style="margin-bottom: 64px"
    >
      <a-row class="form-item-block" :gutter="[0, 4]">
        <a-col>
          <div style="position:relative">
            <a-input
              v-model="form.introduction"
              placeholder="请输入内容"
              :maxLength="500"
              :autoSize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
            />
            <div style="position:absolute;bottom:-5px;right:1px;">
              <span style="background:#fff">{{ form.introduction.length }}/50</span>
            </div>
          </div>
        </a-col>

        <a-col>
          <resource-upload
            :accept="['jpg', 'png', 'jpeg', 'gif', 'mp4']"
            :limit="1024 * 1024 * 200"
            @success="data => form.resourceList.push(data)"
            @error="handleResourcesError"
            @change="handleResourcesChange"
          ></resource-upload>

          <div v-if="form.resourceList.length > 0" style="margin-top:8px">
            <a-row
              v-for="item in form.resourceList"
              :key="item.id"
              style="margin-bottom:8px;line-height:normal"
              type="flex"
              align="middle"
            >
              <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
                <a
                  v-if="item.type === 'VIDEO'"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                  @click="handlePreviewVideo(item.url)"
                >
                  <a-icon type="file" style="margin-right:8px" /><span>{{ item.name }}</span>
                </a>
                <a
                  v-else
                  :href="item.url"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                >
                  <a-icon type="file" style="margin-right:8px" /><span>{{ item.name }}</span>
                </a>
              </a-col>
              <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="removeResource(item)">
                <a-icon type="delete" class="error-color" />
              </a-col>
            </a-row>
          </div>

          <div class="disabled-color" style="line-height:1.5">
            提示：jpg/png/jpeg/gif/MP4格式文件，大小不超过200MB
          </div>
        </a-col>
      </a-row>

      <div v-if="resourceListErr" slot="extra" class="error-color">{{ resourceListErr }}</div>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { cloneDeep, debounce } from 'lodash'
import * as fieldApi from '@/api/field'
import * as commonApi from '@/api/common'
import { ImageUpload, ResourceUpload } from '@/components/_heath'
import { previewVideo } from '@/utils/util'

export default {
  components: {
    ImageUpload,
    ResourceUpload
  },
  data() {
    this.checkName = debounce(this.checkName, 800)

    return {
      form: {
        name: '',
        parentId: undefined,
        isShow: true,
        url: '',
        introduction: '',
        resourceList: []
      },
      oldName: '',
      checkingName: false,
      fieldsOptions: [],
      resourceListErr: ''
    }
  },
  computed: {
    hasPid() {
      return !!this.$route.query.pid
    }
  },
  activated() {
    this.initOptions()
  },
  methods: {
    checkName(rule, value, callback) {
      if (value === this.oldName) {
        callback()
        return
      }
      this.checkingName = true
      fieldApi
        .checkName({ name: value })
        .then(() => {
          callback()
        })
        .catch(err => {
          err.msg ? callback(new Error(err.msg)) : callback()
        })
        .finally(() => {
          setTimeout(() => {
            this.checkingName = false
          }, 1000)
        })
    },
    initData(form) {
      const { id, introduction, isShow, name, parent, resourceList, url } = form
      let { parentId } = form

      if (parent) {
        parentId = parent.id
      }

      this.oldName = name

      this.form = {
        id,
        introduction,
        isShow,
        name,
        parentId,
        resourceList,
        url
      }
    },
    initOptions() {
      commonApi.fieldsOptions().then(res => {
        this.fieldsOptions = res.data.filter(v => v.level === 'MAIN')
      })
    },
    formatForm() {
      const ret = cloneDeep(this.form)

      const {
        $route: {
          query: { pid }
        }
      } = this

      if (pid) {
        ret.parentId = parseInt(pid)
      }

      return { ...ret }
    },
    removeResource(item) {
      const index = this.form.resourceList.indexOf(item)
      if (index !== -1) {
        this.form.resourceList.splice(index, 1)
      }
    },
    handleResourcesError(err) {
      this.resourceListErr = err
    },
    handleResourcesChange() {
      this.resourceListErr = ''
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped>
.form-item-block {
  padding: 16px;
  background: @comp-bg-color;
}
</style>
