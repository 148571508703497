import request from '@/utils/request'

const fieldApi = {
  list: '/fields',
  mainList: '/fields/mains',
  secondaryList: id => `/fields/${id}/childrens`,
  skillList: id => `/fields/${id}/skills`,
  create: '/fields',
  update: id => `/fields/${id}`,
  detail: id => `/fields/${id}`,
  remove: id => `/fields/${id}`,
  sortSkills: '/skills/sorts',
  sort: '/fields/sorts',
  switchStatus: id => `/fields/status/${id}`,
  checkName: '/fields/check-name',
  getPlanHistoryList: id => `/student/${id}/homeschool-plans`,
  getPlanHistoryDetail: planId => `/homeschool-plans/${planId}/plan-skills/`,
  getPlanExportList: studentId => `/export/plan/record/students/${studentId}`, // 导出列表
  addExportPlanRecord: `/export/plan/record`, // 新增导出
  getPlanExportRecord: id => `/export/plan/record/${id}`, // 查询导出计划记录
  deletePlanRecord: id => `/export/plan/record/${id}`, // 删除导出计划
  editRecordStatus: id => `/export/plan/record/status/${id}`, // 撤回发布状态
  editRecordData: id => `/export/plan/record/plan/${id}`, // 编辑回显查询
  commitEditRecord: id => `/export/plan/record/${id}`, // 提交编辑内容
  exportPlan: id => `/export/plan/record/export/${id}` // 预览PDF
}

// 预览PDF
export function exportPlan(parameter) {
  return request({
    url: fieldApi.exportPlan(parameter),
    method: 'get',
    responseType: 'arraybuffer'
  })
}

// 历史计划-详情
export function getPlanHistoryDetail(parameter) {
  const { planId } = parameter
  delete parameter.planId
  return request({
    url: fieldApi.getPlanHistoryDetail(planId),
    method: 'get',
    params: parameter
  })
}

// 历史计划-列表
export function getPlanHistoryList(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.getPlanHistoryList(id),
    method: 'get'
  })
}

export function list(parameter) {
  return request({
    url: fieldApi.list,
    method: 'get',
    params: parameter
  })
}

export function mainList(parameter) {
  return request({
    url: fieldApi.mainList,
    method: 'get',
    params: parameter
  })
}

export function secondaryList(parameter) {
  const { parentId } = parameter
  delete parameter.parentId
  return request({
    url: fieldApi.secondaryList(parentId),
    method: 'get',
    params: parameter
  })
}

export function skillList(parameter) {
  const { parentId } = parameter
  delete parameter.parentId
  return request({
    url: fieldApi.skillList(parentId),
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: fieldApi.create,
    method: 'post',
    data: parameter
  })
}

export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: fieldApi.update(id),
    method: 'put',
    data: parameter
  })
}

export function detail(parameter) {
  return request({
    url: fieldApi.detail(parameter),
    method: 'get'
  })
}

export function remove(parameter) {
  return request({
    url: fieldApi.remove(parameter),
    method: 'delete'
  })
}

export function sort(parameter) {
  return request({
    url: fieldApi.sort,
    method: 'put',
    data: parameter
  })
}

export function sortSkills(parameter) {
  return request({
    url: fieldApi.sortSkills,
    method: 'put',
    data: parameter
  })
}

export function switchStatus(parameter) {
  return request({
    url: fieldApi.switchStatus(parameter),
    method: 'put'
  })
}

export function checkName(parameter) {
  return request({
    url: fieldApi.checkName,
    method: 'get',
    params: parameter
  })
}

// 导出计划列表
export function getPlanExportList(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.getPlanExportList(id),
    method: 'get'
  })
}

// 新增导出计划记录
export function addExportPlanRecord(parameter) {
  return request({
    url: fieldApi.addExportPlanRecord,
    method: 'post',
    data: parameter
  })
}

// 查询导出计划记录详情
export function getPlanExportRecord(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.getPlanExportRecord(id),
    method: 'get'
  })
}

// 删除导出计划
export function deletePlanRecord(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.deletePlanRecord(id),
    method: 'delete'
  })
}

// 撤回发布
export function editRecordStatus(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.editRecordStatus(id),
    method: 'patch'
  })
}

// 编辑回显查询
export function editRecordData(parameter) {
  const { id } = parameter
  return request({
    url: fieldApi.editRecordData(id),
    method: 'get'
  })
}

// 提交编辑内容
export function commitEditRecord(id, parameter) {
  return request({
    url: fieldApi.commitEditRecord(id),
    method: 'put',
    data: parameter
  })
}
